<template>
  <div class="auth-wrapper auth-v1 px-1">
    <div class="py-1">
      <!-- Register v1 -->
      <b-card
        style="background-color: #F0F0F0;"
        class="mb-0"
      >

        <b-link
          v-if="profile"
          class="brand-logo"
        >
          <b-avatar
            size="85px"
            style="border: 3px solid #b71c1c"
            :src="profile.pictureUrl"
          />
        </b-link>
        <div
          v-if="profile"
          cols="6"
        >
          <b-form-group>
            <label class="fg">ชื่อในไลน์</label>
            <b-form-input
              type="text"

              :value="profile.displayName"
              disabled
            />
          </b-form-group>
        </div>

        <!-- <div> -->
        <validation-observer ref="registerForm">
          <div class="mt-2">
            <div>
              <div>
                <label for="sasd">เบอร์โทรศัพท์</label>
                <div class="form-group-10 form-group has-feedback">

                  <div class="country-code dropdown">
                    <div
                      class="dropdown-title"
                    >
                      <i class="flag th" /><strong class="telcode">+66</strong>
                    </div>
                  </div>
                  <div class="tel-number">
                    <validation-provider
                      #default="{ errors }"
                      name="เบอร์โทรศัพท์"
                      rules="required|min:10"
                    >
                      <b-form-input
                        v-model="tel"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10"
                        placeholder="กรุณากรอกเบอร์โทรศัพท์"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] ? 'โปรดระบุหมายเลขโทรศัพท์ให้ถูกต้อง' : '' }}</small>
                    </validation-provider>
                  </div>
                  <div class="clearfix" />
                </div>
              </div>
              <div>
                <b-form-group>
                  <label class="fg">ชื่อ</label>
                  <validation-provider
                    #default="{ errors }"
                    name="ชื่อ"
                    rules="required"
                  >
                    <b-form-input
                      v-model="name"
                      placeholder="กรุณากรอกชื่อ"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] ? 'โปรดระบุชื่อของท่านให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label class="fg">นามสกุล</label>
                  <validation-provider
                    #default="{ errors }"
                    name="นามสกุล"
                    rules="required"
                  >
                    <b-form-input
                      v-model="surname"
                      :state="errors.length > 0 ? false : null"
                      placeholder="กรุณากรอกนามสกุล"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] ? 'โปรดระบุนามสกุลของท่านให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <small class="text-danger">*กรอกชื่อ-นามสกุล ให้ตรงกับชื่อบัญชี หรือ
              ทรูมันนี่วอลเล็ท</small>

            <b-form-checkbox
              v-model="wallet"
              checked="fasle"
              name="check-button"
              switch
              inline
              class="custom-control-warning"
            >
              ทรูมันนี่วอลเล็ท
            </b-form-checkbox>
            <b-form-group v-if="wallet === true">
              <label class="fg">กรอกหมายเลขทรูมันนี่วอลเล็ท</label>
              <b-form-input
                v-model="trueaccno"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
                placeholder="กรุณากรอกหมายเลขทรูมันนี่วอลเล็ท"
                type="number"
              />
            </b-form-group>
            <dev v-else>
              <b-form-group>
                <label class="fg">เลือกธนาคาร</label>
                <div
                  class="box-radio-list"
                >
                  <div
                    class="radio-items"
                    :style="`border: 2px solid ${selected ? selected.bg : '#E6E6E6'};`"
                    @click="showModal"
                  >
                    <label class="input-container radio-area d-flex align-items-center">
                      <div class="inner-radio">
                        <input
                          type="radio"
                          name="bank_type"
                          class="radio radio-bank"
                        ><span class="radiomark" />
                      </div>
                      <img
                        :src="`/bankIcon/${selected ? selected.path_photo : 'bk.png'}`"
                        :style="`background-color: ${selected ? selected.bg : '#FF6700'};`"
                        style="width:32px;border-radius:.25rem;padding:7px;margin-right:10px"
                      > <strong class="text-radio">{{ selected ? selected.name : 'กรุณาเลือกธนาคาร' }}</strong>
                    </label>
                  </div>
                </div>
              </b-form-group>

              <b-modal
                ref="my-modal"
                centered
                title="Vertically Centered"
                hide-footer
                hide-header
                hide-header-close
              >
                <div class="row">
                  <div
                    v-for="option in bank"
                    :key="option.bankcode"
                    class="col-4 mb-2 mt-1 text-center"
                    @click="select(option)"
                  >
                    <b-avatar
                      size="80"
                      :src="`/bankIcon/${option.path_photo}`"
                      :style="`background-color: ${option.bg}; padding: 10px; border: 4px solid #1e110d66; border-radius: 0;`"
                    />
                    <div style="margin-top: 5px">
                      {{ option.codename }}
                    </div>
                  </div>
                </div>
              </b-modal>
              <b-form-group v-if="selected">
                <label class="fg">กรอกบัญชีธนาคาร</label>
                <validation-provider
                  #default="{ errors }"
                  name="บัญชีธนาคาร"
                  rules="required|min:10"
                >
                  <b-form-input
                    v-model="accno"
                    :state="errors.length > 0 ? false : null"
                    placeholder="กรุณากรอกเลขบัญชี"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="13"
                    type="tel"
                  />
                  <small class="text-danger">{{ errors[0] ? 'โปรดระบุเลขบัญชีของท่านให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>
            </dev>
            <b-form-group>
              <label class="fg">รู้จักเราผ่านช่องทาง :</label>
              <b-form-select
                v-model="knowby"

                :options="options"
                placeholder=""
              />
            </b-form-group>
            <b-form-group>
              <label class="fg">รหัสแนะนำ</label>
              <b-form-input
                v-model="invited_by"
                placeholder="กรอกรหัสแนะนำ"

                type="text"
              />
            </b-form-group>
          </div>

          <div class="text-center mt-1">
            <b-button
              variant="primary"
              block
              @click.prevent="validationForm"
            >
              ยืนยันข้อมูล
            </b-button>
          </div>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BAvatar,
  // BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  // BInputGroup,
  // BInputGroupAppend,
  BFormCheckbox,
  BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import liff from '@line/liff'

export default {
  components: {
    // BSV
    BAvatar,
    BCard,
    BLink,
    // BCardText,
    BFormSelect,
    BButton,
    BFormInput,
    BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      bank_type: [],
      profile: JSON.parse(localStorage.getItem('lineProfile')),
      passValue: '',
      Ip: null,
      wallet: '',
      regEmail: '',
      username: '',
      password: '',
      status: '',
      ref: '',
      match: '',
      // validation rules
      name: '',
      required,
      tel: '',
      surname: '',
      trueaccno: '',
      otp: '',
      selected: null,
      s_bank: null,
      accno: '',
      knowby: null,
      promo: '',
      promotion: '',
      invited_by: '',
      noinv: null,
      bank: [{ value: null, text: 'กรุณาเลือกธนาคาร' }],
      selects: null,
      options: [
        { value: null, text: 'คุณรู้จักเราผ่านช่องทางใด' },
        { value: 1, text: 'Facebook' },
        { value: 2, text: 'Youtube' },
        { value: 3, text: 'Tik Tok' },
        { value: 4, text: 'Google' },
        { value: 5, text: 'SMS' },
        { value: 6, text: 'เพื่อนแนะนำ' },
      ],
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConToggleIcon() {
      return this.passwordConFieldType === 'passValue'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    liff.init({ liffId: '1657385644-5q8k432M' }, async () => {
      if (liff.isLoggedIn()) {
        await liff.getProfile().then(prof => {
          localStorage.setItem('lineProfile', JSON.stringify(prof))
          this.profile = prof
        }).catch(err => {
          console.log(err)
        })
      } else {
        liff.login()
      }
    }, err => console.error(err.code, err.message))
    this.getBank()
    if (this.$route.params.invcode) {
      console.log(this.$route.params.invcode)
      this.knowby = 6
      this.invited_by = this.$route.params.invcode
    }
    if (this.$route.params.noinv) {
      console.log(this.$route.params.noinv)
      this.knowby = 6
      this.noinv = this.$route.params.noinv
    }
    if (localStorage.getItem('invcode')) {
      this.knowby = 6
      this.invited_by = localStorage.getItem('invcode')
    }
    if (this.$route.query.ref) {
      this.knowby = 6
      this.invited_by = this.$route.query.ref
    }
    if (this.$route.query.register) {
      this.$router.push('/login')
    }

    // console.log(this.$route.query.ref)
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          if (this.wallet === true) {
            this.s_bank = 'truewallet'
            this.accno = null
          } else {
            this.trueaccno = null
          }
          const formData = {
            role: 0,
            tel: this.tel,
            fname: this.name,
            sname: this.surname,
            bank: this.s_bank,
            acc_no: this.accno,
            noinv: this.noinv,
            trueaccno: this.trueaccno,
            knowby: this.knowby,
            invited_by: this.invited_by,
            line_userId: this.profile.userId,
            line_pictureUrl: this.profile.pictureUrl,
          }
          console.log(formData)
          this.$http
            .post('https://khunyingapi.linelotto.vip/api/auth/register', formData)
            .then(() => {
              // this.$router.replace('https://line.me/R/ti/p/@011jxexk')
              window.location.href = 'https://line.me/R/ti/p/@670wdtom'
              // liff.closeWindow()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Form Submitted',
          //     icon: 'EditIcon',
          //     variant: 'success',
          //   },
          // })
        }
      })
    },
    async GetIp() {
      try {
        const { data: res } = await axios.get('https://api.ipify.org')
        this.Ip = res
      } catch (e) {
        this.Ip = null
      }
    },
    getOTP() {
      // this.SwalError(
      //   'ปิดรับสมัครชั่วคราว เนื่องจากรอบของการแจกเครดิตฟรีหมดแล้วค่ะ',
      // )
      const formData = {
        tels: this.tel,
      }
      this.$http
        .post('https://khunyingapi.linelotto.vip/api/otp/get', formData)
        .then(response => {
          this.ref = response.data.ref
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    checkOTP() {
      const formData = {
        tels: this.tel,
        ref: this.ref,
        otps: this.otp,
      }
      this.$http
        .post('https://khunyingapi.linelotto.vip/api/otp/check', formData)
        .then(response => {
          this.match = response.data.match
          this.username = response.data.username
          // this.Success('ยืนยัน OTP สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    select(option) {
      this.$refs['my-modal'].hide()
      this.selected = option
      this.s_bank = option.bankcode
    },
    getBank() {
      this.$http
        .get('https://khunyingapi.linelotto.vip/api/bank/list')
        .then(response => {
          // console.log(response.data)
          this.bank = response.data
        })
        .catch(error => {
          console.log(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.sty {
  color: #ffffff;
  background-color: #82868b;
  border-bottom: 1px solid #fff;
}
.iner {
  position: relative;
  z-index: 2;
}
.mainbg {
  background: rgb(58, 8, 8);
  background: linear-gradient(
    158deg,
    rgb(21, 31, 20) 0%,
    rgb(224, 218, 218) 48%,
    rgb(31, 118, 5) 96%
  );
  width: 100%;
  min-height: 100vh;
  left: 0;
  position: relative;
  z-index: 1;
  display: flex;
  text-align: inherit;
}
.mainbg:after,
.mainbg:before {
  content: '';
  width: 100%;
  position: absolute;
  z-index: 0;
}
.mainbg:before {
  background: url(/gradiant-top.svg) top no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  height: auto;
}
.mainbg:after {
  background: url(/gradiant-bottom.svg) bottom no-repeat;
  background-size: 60% auto;
  bottom: 0;
  height: 100vh;
}
.form-control {
  height: 44px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
}
[dir] .dropdown-item {
    padding: 0.65rem 0.9rem !important;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
}
.btn-outline-primary {
    color: #ffffff !important;
        border: 1px solid #b6b6b6 !important;
}
.dropdown-item {
    height: 50px !important;
    line-height: 32px;
}

</style>

<style lang="scss" scoped>
@import url('https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css');
@import '@core/scss/vue/pages/page-auth.scss';

// .style-1 {
//   // background-color: #141414;
//   .otp-input-8-field input {
//     border-radius: 4px;
//     color: #000000;
//     height: 42px;
//     width: 42px;
//     margin-left: 2px;
//     margin-right: 2px;
//     background-color: #ffffff;
//     border: 1px solid #ad0000;
//     &:not(:last-child) {
//       margin-right: 0.5rem;
//     }
//   }
// }
</style>
